<template>
  <div id="CryptoCurency">
    <div class="wrapper-text">
      <h2>مشاهده قیمت ارزهای دیجیتال با سوکت</h2>
      <p>در اینجا با سوکت Crypto Currency ها دریافت و قیمت به نمایش می آید. سوکت دارای محدودیت های زیادی برای برنامه
        نویسی میباشد.</p>
      <div class="alert alert-success" v-show="this.status.length > 0">
        {{ this.status }}
      </div>
      <div class="table-responsive">
        <table class="table table-bordered align-middle text-center">
          <thead class="table-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">ایکن</th>
            <th scope="col">نماد</th>
            <th scope="col">نماد فارسی</th>
            <th scope="col">قیمت لحظه</th>
            <th scope="col">قیمت به تومان</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="this.bitcoinClass">
            <th scope="row">1</th>
            <td><img src="../../public/img/currency/bitcoin.png" width="32" height="32"
                     alt="بیت کوین" title="بیت کوین"/></td>
            <td>Bitcoin</td>
            <td>بیت کوین</td>
            <td>{{ this.bitcoin }}</td>
            <td>{{ bitcoinToman }}</td>
          </tr>
          <tr :class="this.ethereumClass">
            <th scope="row">2</th>
            <td><img src="../../public/img/currency/ethereum.png" width="32" height="32"
                     alt="اتریوم" title="اتریوم"/></td>
            <td>Ethereum</td>
            <td>اتریوم</td>
            <td>{{ this.ethereum }}</td>
            <td>{{ ethereumToman }}</td>
          </tr>
          <tr :class="this.xrpClass">
            <th scope="row">3</th>
            <td><img src="../../public/img/currency/xrp.png" width="32" height="32"
                     alt="ایکس آر پی" title="ایکس آر پی"/></td>
            <td>XRP</td>
            <td>ایکس آر پی</td>
            <td>{{ this.xrp }}</td>
            <td>{{ xrpToman }}</td>
          </tr>
          <tr :class="this.tronClass">
            <th scope="row">4</th>
            <td><img src="../../public/img/currency/tron.png" width="32" height="32"
                     alt="ترون" title="ترون"/></td>
            <td>Tron</td>
            <td>ترون</td>
            <td>{{ this.tron }}</td>
            <td>{{ tronToman }}</td>
          </tr>
          <tr :class="this.cardanoClass">
            <th scope="row">5</th>
            <td><img src="../../public/img/currency/cardano.png" width="32" height="32"
                     alt="کاردانو" title="کاردانو"/></td>
            <td>Cardano</td>
            <td>کاردانو</td>
            <td>{{ this.cardano }}</td>
            <td>{{ cardanoToman }}</td>
          </tr>
          <tr :class="this.tetherClass">
            <th scope="row">6</th>
            <td><img src="../../public/img/currency/tether.png" width="32" height="32"
                     alt="تتر" title="تتر"/></td>
            <td>Tether</td>
            <td>تتر</td>
            <td>{{ this.tether }}</td>
            <td>{{ tetherToman }}</td>
          </tr>
          <tr :class="this.bnbClass">
            <th scope="row">7</th>
            <td><img src="../../public/img/currency/bnb.png" width="32" height="32"
                     alt="بی ان بی" title="بی ان بی"/></td>
            <td>BNB</td>
            <td>بی ان بی</td>
            <td>{{ this.bnb }}</td>
            <td>{{ bnbToman }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageCryptoCurrency",
  data() {
    return {
      status: "",
      connection: null,
      bitcoin: 0,
      bitcoinClass: "",
      ethereum: 0,
      ethereumClass: "",
      xrp: 0,
      xrpClass: "",
      tron: 0,
      tronClass: "",
      cardano: 0,
      cardanoClass: "",
      tether: 0,
      tetherClass: "",
      bnb: 0,
      bnbClass: "",
    }
  },
  computed: {
    bitcoinToman() {
      return this.separate((this.bitcoin * 25000).toFixed()) + ' تومان'
    },
    ethereumToman() {
      return this.separate((this.ethereum * 25000).toFixed()) + ' تومان'
    },
    xrpToman() {
      return this.separate((this.xrp * 25000).toFixed()) + ' تومان'
    },
    tronToman() {
      return this.separate((this.tron * 25000).toFixed()) + ' تومان'
    },
    cardanoToman() {
      return this.separate((this.cardano * 25000).toFixed()) + ' تومان'
    },
    tetherToman() {
      return this.separate((this.tether * 25000).toFixed()) + ' تومان'
    },
    bnbToman() {
      return this.separate((this.bnb * 25000).toFixed()) + ' تومان'
    },
  },
  methods: {
    webSocketFetch() {
      this.status = "شروع اتصال به وب سوکت"
      let assets = "bitcoin,ethereum,ripple,tether,binance-coin,tron,cardano"
      this.connection = new WebSocket(`wss://ws.coincap.io/prices?assets=${assets}`)
      this.connection.onopen = (event) => {
        this.status = "درخواست اتصال به سرور ..."
        console.log(event)
        this.status = "اتصال به وب سوکت با موفقیت انجام شد."
        setTimeout(() => {
          this.status = ""
        }, 3000)
      }
      this.connection.onmessage = (event) => {
        let result = JSON.parse(event.data);
        // console.log(result)
        if (result.bitcoin !== undefined && result.bitcoin.length > 0) {
          if (this.bitcoin > result.bitcoin) {
            this.bitcoinClass = "flash-red"
          } else {
            this.bitcoinClass = "flash-green"
          }
          setTimeout(() => {
            this.bitcoinClass = false
          }, 500)
          this.bitcoin = result.bitcoin
        }
        if (result.ethereum !== undefined && result.ethereum.length > 0) {
          if (this.ethereum > result.ethereum) {
            this.ethereumClass = "flash-red"
          } else {
            this.ethereumClass = "flash-green"
          }
          setTimeout(() => {
            this.ethereumClass = ""
          }, 500)
          this.ethereum = result.ethereum
        }
        if (result.ripple !== undefined && result.ripple.length > 0) {
          if (this.xrp > result.ripple) {
            this.xrpClass = "flash-red"
          } else {
            this.xrpClass = "flash-green"
          }
          setTimeout(() => {
            this.xrpClass = ""
          }, 500)
          this.xrp = result.ripple
        }
        if (result.tron !== undefined && result.tron.length > 0) {
          if (this.tron > result.tron) {
            this.tronClass = "flash-red"
          } else {
            this.tronClass = "flash-green"
          }
          setTimeout(() => {
            this.tronClass = ""
          }, 500)
          this.tron = result.tron
        }
        if (result.cardano !== undefined && result.cardano.length > 0) {
          if (this.cardano > result.cardano) {
            this.cardanoClass = "flash-red"
          } else {
            this.cardanoClass = "flash-green"
          }
          setTimeout(() => {
            this.cardanoClass = ""
          }, 500)
          this.cardano = result.cardano
        }
        if (result.tether !== undefined && result.tether.length > 0) {
          if (this.tether > result.tether) {
            this.tetherClass = "flash-red"
          } else {
            this.tetherClass = "flash-green"
          }
          setTimeout(() => {
            this.tetherClass = ""
          }, 500)
          this.tether = result.tether
        }
        if (result['binance-coin'] !== undefined && result['binance-coin'].length > 0) {
          if (this.bnb > result['binance-coin']) {
            this.bnbClass = "flash-red"
          } else {
            this.bnbClass = "flash-green"
          }
          setTimeout(() => {
            this.bnbClass = ""
          }, 500)
          this.bnb = result['binance-coin']
        }
      }
      this.connection.onerror = (error) => {
        this.status = `اتصال به وب سوکت با خطا همراه شد. ${error.message}`
      };
    },
    separate(Number) {
      Number += '';
      Number = Number.replace(',', '');
      let x = Number.split('.');
      let y = x[0];
      let z = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y))
        y = y.replace(rgx, '$1' + ',' + '$2');
      return y + z;
    }
  },
  created() {
    this.webSocketFetch()
  }
}
</script>

<style scoped>
.flash-red {
  background-color: rgba(244, 67, 54, 0.19);
}

.flash-green {
  background-color: rgba(24, 198, 131, 0.19);
}

</style>